import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["heatmap"];

  handleCategorySelect(e) {
    // Do not refresh chart if selecting the same target
    if (this.heatmapTarget.dataset.dataUrl === e.currentTarget.dataset.dataUrl) return;

    this.updateDatasetData(e);
    this.dispatchHeatmapRefreshEvent();
  }

  dispatchHeatmapRefreshEvent() {
    this.heatmapTarget.dataset.categoryHeatmapBuildingRefreshValue = true;
  }

  updateDatasetData(e) {
    this.heatmapTarget.dataset.dataUrl = e.currentTarget.dataset.dataUrl;
    this.heatmapTarget.dataset.heatmapType = e.currentTarget.dataset.heatmapType;
  }
}
