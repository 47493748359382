import { Controller } from "stimulus";
import { EventGeckoChartRendered } from "../../events";

export default class extends Controller {
  static targets = ["chart"];

  chartUrls = null;
  selectedChart = null;
  selectedRange = null;

  connect() {
    this.chartUrls = JSON.parse(this.element.dataset.chartUrls);
    this.selectedChart = this.chartTarget.dataset.chartType;
    this.selectedRange = this.chartTarget.dataset.selectedRange;
  }

  handleOptionChange(e) {
    const optionType = e.currentTarget.dataset.option;
    const optionKey = e.currentTarget.dataset.key;

    if (!["selectedChart", "selectedRange"].includes(optionType)) {
      console.error(`Unknown option type '${optionType}', valid types are 'selectedChart' and 'selectedRange'.`);
      return;
    }

    if (this[optionType] === optionKey) {
      return;
    }

    if (optionType === "selectedChart") {
      this.chartTarget.dataset.chartType = optionKey;
    }

    this[optionType] = optionKey;
    this.refreshChartSettings();
  }

  refreshChartSettings() {
    this.chartTarget.dataset.dataUrls = JSON.stringify(this.chartUrls?.[this.selectedChart]?.[this.selectedRange]);
    this.chartTarget.dataset.dataGroupingDuration = this.selectedRange;
    this.chartTarget.dataset.coinsChartBuildingRefreshValue = true;
  }
}
