import { Controller } from "stimulus";
import { EventGeckoChartRendered } from "../../events";

export default class extends Controller {
  static targets = ["chart"];

  chartInstance = null;
  chartUrls = null;

  selectedChart = "market_cap";
  selectedRange = null;

  connect() {
    window.addEventListener(EventGeckoChartRendered, this.onChartRerender.bind(this));

    this.chartUrls = JSON.parse(this.element.dataset.chartUrls);
    this.selectedRange = this.chartTarget.dataset.selectedRange;
  }

  handleOptionChange(e) {
    const optionType = e.currentTarget.dataset.option;
    const optionKey = e.currentTarget.dataset.key;

    if (!["selectedChart", "selectedRange"].includes(optionType)) {
      console.error(`Unknown option type '${optionType}', valid types are 'selectedChart' and 'selectedRange'.`);
      return;
    }

    if (this[optionType] === optionKey) {
      return;
    }

    if (optionType === "selectedChart") {
      this.chartTarget.dataset.chartType = optionKey;
    }

    e.currentTarget.classList.add("selected");

    this[optionType] = optionKey;
    this.refreshChartSettings();
  }

  refreshChartSettings() {
    this.chartTarget.dataset.dataUrl = this.chartUrls?.[this.selectedChart]?.[this.selectedRange];
    this.chartTarget.dataset.dataGroupingDuration = this.selectedRange;

    this.chartTarget.dataset.categoryChartBuildingRerenderValue = true;
  }

  onChartRerender(e) {
    this.chartInstance = e.detail.chart;
  }
}
